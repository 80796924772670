import { useEffect, useState } from "react";
import filterFloor from "../assets/filterFloor.svg";
import { useUserContext } from "../context/user_context";
import AuthUser from "./AuthUser";
const FloorTopbar = (props) => {
  const { user, http } = AuthUser();
  const { selectedFactoryId, changeSelectedFactoryId } = useUserContext();
  const [floors, setFloors] = useState([]);
  const [displayFloors, setDisplayFloors] = useState("none");

  // useEffect(() => {
  //   if (user) {
  //     props?.setSelectedFloorId(user.default_floor);
  //   }
  // }, [user]);

  const setFloorsData = async () => {
    const { data } = await http.get(
      `/pms/v1/processes`
    );

    const additionalObject = { process_name: "All", process_id: 0 };

    // const myArray = [
    //   additionalObject,
    //   ...Object.entries(data).map(([key, value]) => ({
    //     name: value,
    //     id: key,
    //   })),
    // ];

    data && setFloors([additionalObject,...data]);

    if (data?.length > 0) {
      props?.setSelectedFloorId(data[0]?.id);
    }
    
    //set default compartment
    const selectedFloor = floors?.find(
      (floor) => floor.process_id == user?.default_floor
    );
    props?.setSelectedCompartment(selectedFloor?.process_name);
  };

  useEffect(() => {
    setFloorsData();
  }, [selectedFactoryId]);

  const handleSelectFloor = (e) => {
    const value = e.target.value;
    props.setSelectedFloorId(value);
    const selectedFloor = floors?.find((floor) => floor.process_id === value);
    props.setSelectedCompartment(selectedFloor?.process_name);
    setDisplayFloors("none");
  };
  const options = [
    { key: "edit", icon: "edit", text: "Edit Post", value: "edit" },
    { key: "delete", icon: "delete", text: "Remove Post", value: "delete" },
    { key: "hide", icon: "hide", text: "Hide Post", value: "hide" },
  ];


  return (
    <div
    
    >
      {/* <div
        className="d-flex justify-content-between"
        style={{
          maxWidth: "1100px",
          margin: "0 auto",
          overflow: "visible",
          paddingLeft: "0.5rem",
          paddingRight: "0.5rem",
        }}
      >
        <div className="d-flex justify-content-start">
          <span className="pt-2 pe-2  mt-2" style={{ fontWeight: "bold" }}>
            Select Process
          </span>
          <div>
            <div
              className="d-flex justify-content-between border border-1 rounded-pill align-middle fw-bold"
              style={{
                width: "220px",
                marginTop: "6px",
                fontSize: "13px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                displayFloors == "none"
                  ? setDisplayFloors("block")
                  : setDisplayFloors("none");
              }}
            >
              <span className="ms-4 p-2 text-dark">
        

               
              </span>
              <img
                src={filterFloor}
                style={{
                  width: "15px",
                  marginLeft: "2px",
                  marginRight: "10px",
                }}
              />
            </div>

            <div style={listFloor(displayFloors)}>
              {floors.map((f, i) => {
                if (i != floors.length - 1)
                  return (
                    <div
                      key={i}
                      style={{
                     
                        fontSize: "13px",
                        height: "30px",
                        cursor: "pointer",
                      }}
                      className="border-bottom border-1 p-2"
                      onClick={(e) => handleSelectFloor(f.id)}
                    >
                      {f.name}
                    </div>
                  );
                else
                  return (
                    <div
                      key={i}
                      style={{
                     
                        fontSize: "13px",
                        cursor: "pointer",
                      }}
                      className="p-2"
                      onClick={(e) => handleSelectFloor(f.id)}
                    >
                      {f.name}
                    </div>
                  );
              })}
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-end"
          style={{ cursor: "pointer" }}
        >
          <span className="fs-7 m-auto fw-bold">
            {props.selectedCompatment &&
              `Live Data For ${props.selectedCompatment}`}
          </span>
        </div>
      </div> */}
      <div className="row">
        <label for="staticEmail" className="col-md-2 col-form-label fs-5">
          Select Process
        </label>
        <div className="col-md-4">
          <select
            className="form-select rounded-pill"
            onChange={handleSelectFloor}
            style={{cursor:"pointer"}}
          >
            {floors?.map((f) => (
              <option key={f.process_id} value={f.process_id}>
                {f.process_name}
              </option>
            ))}
          </select>{" "}
        </div>
      </div>
    </div>
  );
};
const floorStyle = {
  backgroundColor: "#FFFFFF",
  height: "50px",
  width: "100%",
  marginBottom: "10px",
  overflow: "visible",
};
const listFloor = (display) => {
  return {
    position: "relative",
    margin: "0 auto",
    backgroundColor: "#FFFF",
    width: "200px",
    zIndex: "9999",
    borderRadius: "5px",
    border: "1px solid #DEE2E6",
    display: display,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  };
};
export default FloorTopbar;
