import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import moment from "moment";
import { AiOutlineCalendar, AiOutlineClockCircle } from "react-icons/ai";
import Skeleton from "@material-ui/lab/Skeleton";
import { closestTime } from "../utils/helpers";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const {presentTemperature } = payload[0].payload;
    return (
      <div className="custom-tooltip shadow text-start">
        <h5 className="text-center">{`${presentTemperature}`} &deg;C</h5>
        <p>
          <AiOutlineClockCircle size={18} />
          <span className="text-center">{` ${moment(payload[0].payload['acquisition-time']).format(
            "hh:mm A"
          )}`}</span>
        </p>
        {/* <p>
          <AiOutlineCalendar size={18} />
          <span className="text-center"> {date}</span>
        </p> */}
      </div>
    );
  }

  return null;
};

const SingleMachineProductionGraph = ({
  report,
  diffDays,
  loading,
  state,
  cTf,
  startDate,
  endDate,
}) => {
  const calculateProductionData = () => {
    const final_array = [];
    const mutated_data = [];

    let modifiedSData = [];

    function calculatTemp(time, date, data = []) {
      data?.map(d => {
        d.temperature > 0 && modifiedSData.push({
          time,
          date,
          rpm: d.temperature,
          hum:d.humidity
        });
      });
      return modifiedSData;
    }

    (function calculateData() {
      const data = report?.map((r) => calculatTemp(r.time, r.date, r.sdata));
    })();

    const arr = ["00:00:00", "06:00:00", "12:00:00", "18:00:00"];
    const predefinedTimesInMs = arr.map((t) =>
      moment(t, "HH:mm:ss").format("x")
    );
    let reversed_report = modifiedSData;

    startDate = moment(startDate).format("YYYY-MM-DD");
    endDate = moment(endDate).format("YYYY-MM-DD");

    for (
      let m = moment(startDate);
      m.diff(endDate, "days") <= 0;
      m.add(1, "days")
    ) {
      let key_date = m.format("YYYY-MM-DD");

      let time_in_ms_arr = [];

      for (let r of reversed_report) {
        if (r["date"] === key_date) {
          const time_in_ms = moment(r.time, "HH:mm:ss").format("x");
          time_in_ms_arr.push(time_in_ms);
        }
      }

      for (let onePreTimeInMs of predefinedTimesInMs) {
        if (m.isSame(moment().utcOffset("+0600"), "day")) {
          if (onePreTimeInMs > moment().format("x")) {
            continue;
          }
        }
        let closest = closestTime(time_in_ms_arr, onePreTimeInMs);
        closest = moment(closest, "x").format("HH:mm:ss");
        // const onePreTime = moment(onePreTimeInMs, 'x').format('hh:mm A');
        const updated_time = moment(onePreTimeInMs, "x").format("HH:mm:ss");

        for (let data of reversed_report) {
          if (data["date"] === key_date) {
            if (data["time"] === closest) {
              const new_data = {
                ...data,
                name: onePreTimeInMs,
                updated_time: updated_time,
              };
              mutated_data.push(new_data);
            }
          }
        }
      }
    }

    for (let data of reversed_report) {
      for (let m_data of mutated_data) {
        if (
          data["date"] === m_data["date"] &&
          data["time"] === m_data["time"]
        ) {
          Object.assign(data, {
            ...data,
            name: m_data["name"],
            updated_time: m_data["updated_time"],
          });
        } else if (!("name" in data)) {
          Object.assign(data, {
            ...data,
            name: moment(data["time"], "HH:mm:ss").format("x"),
          });
        }
      }
    }

    reversed_report.map((r) => {
      final_array.push({
        name: r.name,
        Time: r.updated_time ? r.updated_time : r.time,
        rpm: r.rpm && r.rpm,
        hum: r.hum && r.hum,
        date: r.date,
      });
    });
    return final_array;
  };

  // const production_graph_data = calculateProductionData();

  // function formatXAxis(tickItem) {
  //   console.log(tickItem)
  //   let splitted = moment(tickItem, "x").format("HH:mm").split(":");
  //   if (splitted[1] !== "00") {
  //     let m = moment(tickItem, "x");
  //     if (parseInt(splitted[1]) >= 30) {
  //       tickItem =
  //         m.minute() || m.second() || m.millisecond()
  //           ? m.add(1, "hour").startOf("hour")
  //           : m.startOf("hour");
  //     } else {
  //       tickItem = m.startOf("hour");
  //     }
  //   }
  //   return moment(tickItem, "x").format("HH:mm");
  // }

  function formatXAxis(tickItem) {
    return moment(tickItem).format("HH mm a");
  }
  function formatXAxis2(tickItem) {
    return moment(tickItem).format("MMM DD");
  }

  if (loading) {
    return <Skeleton variant="rect" width="100%" height={300} />
  }

  return (
    <Wrapper className="mb-4">
      <div className="chart">
        <ResponsiveContainer>
          <LineChart
            width={500}
            height={300}
            data={report}
            margin={{
              right: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} strokeWidth={0.5}/>
            <XAxis
              dataKey="acquisition-time"
              xAxisId={0}
              interval={360}
              tickFormatter={formatXAxis}
            />

            <XAxis
              dataKey="acquisition-time"
              tickFormatter={formatXAxis2}
              xAxisId={1}
              interval={1424}
              tickLine={false}
            />
            <YAxis
              dataKey="presentTemperature"
              type="number"
              allowDataOverflow={true}
              domain={[
                (dataMin) => Math.abs(dataMin) - 5,
                (dataMax) => Math.abs(dataMax) + 5,
              ]}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="presentTemperature"
              stroke="#4040F2"
              strokeWidth={2}
              dot={false}
            />
           
           
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .chart {
    width: 100%;
    height: 300px;
    /* background-color: var(--clr-primary-1); */
    border-radius: 8px;
  }
  .custom-tooltip {
    background-color: var(--clr-grey-10);
    padding: 5px;
    text-align: center;
    color: var(--clr-primary-1);
  }
  .iconDetails {
    margin-top: 5px;
    width: 30px;
    height: 25px;
    padding-right: 5px;
  }
`;

export default SingleMachineProductionGraph;
