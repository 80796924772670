import React from "react";

const PasteMixing = ({sensor_name,sensor_data,color}) => {
  const {temperature,oxideWeight,dmWaterWeight,acidWeight,batchNo,statusCode,runTime} =  sensor_data;

  return (
    <div className="col-md-4 mb-4">
      <div
        className="card bg-white ps-4 pt-3 pb-3 shadow"
        style={{ borderRadius: "5px" }}
      >
        <div className="d-flex justify-content-start ">
          <div
            style={{
              height: "15px",
              width: "15px",
              backgroundColor: statusCode == 'on' ? 'green' : 'red',
              borderRadius: "50%",
              marginTop: "1%",
            }}
          ></div>
         <p className="ms-2 fw-bold text-info text-uppercase">{sensor_name}</p>
        </div>
        <span className="fw-bold fs-6 mt-2">Temperature</span>
        <span className="fs-4 mt-2 text-muted fw-bold">{parseFloat(temperature)?.toFixed(2)} &deg;C</span>
        <span className="fw-bold fs-6 mt-2">Oxide Weight </span>
        <span className="fs-4 mt-2 text-muted fw-bold">{parseFloat(oxideWeight)?.toFixed(2)} kg</span>
        <span className="fw-bold fs-6 mt-2">DM water Weight </span>
        <span className="fs-4 mt-2 text-muted fw-bold">{parseFloat(dmWaterWeight)?.toFixed(2)} kg</span>
        <span className="fw-bold fs-6 mt-2">Acid Weight</span>
        <span className="fs-4 mt-2 text-muted fw-bold">{parseFloat(acidWeight)?.toFixed(2)} kg</span>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-grid">
            <span className="fw-bold fs-6 mt-2">Status</span>
            <span className="fs-4 mt-2 text-muted fw-bold">{statusCode}</span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-grid">
            <span className="fw-bold fs-6 mt-2">Running Time</span>
            <span className="fs-4 mt-2 text-muted fw-bold">{runTime}</span>
          </div>
        </div>
        <span className="fw-bold fs-6 mt-2">Batch No</span>
        <span className="fs-4 mt-2 text-muted fw-bold">{batchNo || 1}</span>
      </div>
    </div>
  );
};

export default PasteMixing;
