import React from "react";
import Motion from "./Motion";
import SingleMachineProductionGraph from "./SingleMachineProductionGraph";
import { WiDaySnowWind, WiRaindrop } from "react-icons/wi";
import SingleMachineProductionGraph2 from "./SignleMachineProductionGraph2";
import GraphData from "./RahimAfrooz/GrpahData";
import { graphCount } from "../utils/constants";
import { Hero } from "./Hero";

const GraphView = ({
  report,
  loading,
  state,
  handleCheck,
  cTf,
  reportType,
}) => {
  if (report?.length < 1 || report?.length === undefined) {
    return (
      <div className="p-2 m-2">
        <h3 className="text-center my-4">No Data Found</h3>
        <h4 className="text-center my-4">
          Select Process and Unit to See Reports
        </h4>
      </div>
    );
  } else {
    const length = report?.length;

    const date1 = report && new Date(report[length - 1]?.date);
    const date2 = report && new Date(report[0]?.date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    const selectedGraphs = graphCount?.find((grpah) => grpah.root == reportType);

    return (
      <>
        {selectedGraphs?.graphs?.map((grpah) => (
          <GraphData
            report={report}
            diffDays={diffDays}
            loading={loading}
            state={state}
            handleCheck={handleCheck}
            cTf={cTf}
            data_key={grpah.dataKey}
            title={grpah.title}
            startDate={date1}
            endDate={date2}
          />
        ))}
      </>
    );
  }
};

export default GraphView;
