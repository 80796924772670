import { default as React, useEffect, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useAppContext } from "../context/app_context";
import MenuDropDown from "./MenuDropdown";
import NotificationButton from './NotificationButton';
const Navbar = () => {
  const location = useLocation();
  const { openSidebar, closeSidebar } = useAppContext();
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showMenu, setShowMenu] = useState(false);

  // if(window.innerWidth <990){
  //   styles = {
  //     '--sidenav-display':"none"
  //   }
  // }

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <>
      {user ? (
        <Wrapper className="section">
          <div className="section-center d-flex justify-content-between">
            <div className="nav-header">
              {/* <Link to="/" onClick={closeSidebar}>
                <img src={logo} alt="Factory Next" />
              </Link> */}
              {windowWidth < 990 && (
                <RxHamburgerMenu
                  size={25}
                  className="me-2"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => setShowMenu(!showMenu)}
                />
              )}

              {showMenu && <MenuDropDown setShowMenu={setShowMenu} showMenu={showMenu}/>}

              <span className="fw-bolder text-capitalize">
               Process Monitoring
              </span>

              {/* <button
                className="nav-toggle"
                type="button"
                onClick={openSidebar}
              > */}
              {/* <FaAlignRight /> */}
              {/* </button> */}
            </div>
            <div className="d-flex justify-content-start">
              <span className=" fw-bolder m-auto pe-2">{ Object.values(user.companies) || `Rahim Afroz Battery Ltd.`}</span>
              {/* <div className="d-flex justify-content-start"> */}
                <NotificationButton windowWidth={windowWidth}/>
                {/* <img  /> */}
                
              {/* </div> */}
            </div>
          </div>
        </Wrapper>
      ) : null}
    </>
  );
};

const Wrapper = styled.section`
  background-color: #ffffff;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-bottom: 1px solid #dee2e6; */
  .nav-center {
    width: 100vw;
    margin: 0 auto;
    max-width: var(--max-width);
  }
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 165px;
      margin-left: -15px;
    }
  }

  .nav-toggle {
    /* margin-top:400px; */
    background: transparent;
    border: transparent;
    color: var(--clr-primary-5);
    cursor: pointer;
    svg {
      font-size: 2rem;
    }
  }
  .nav-links {
    display: none;
    font-weight: 600;
  }
  .notification-btn-wrapper {
    display: none;
  }
  a {
    color: #000 !important;
  }
  a.active {
    // border-bottom: 2px solid #17a2b8;
    color: #04122c !important;
  }
  span {
    font-size: 16px;
  }

  .notification-btn-wrapper {
    display: grid;
  }
  @media (min-width: 992px) {
    .nav-toggle {
      display: none;
    }
    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
    }
    .nav-links {
      display: flex;
      justify-content: center;
      li {
        margin: 0 0.5rem;
      }
      a {
        color: var(--clr-grey-3);
        font-size: 0.9rem;
        text-transform: capitalize;
        letter-spacing: var(--spacing);
        padding: 0.5rem;
        &:hover {
          border-bottom: 2px solid #17a2b8;
        }
      }
    }
    
  }
`;
export default Navbar;
