import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AuthUser from "../components/AuthUser";
import FloorTopbar from "../components/FloorTopbar";
import { useUserContext } from "../context/user_context";
import { getLiveWeatherData } from "../utils/Api";
import { intervalTime } from "../utils/constants";
import Curing from "../components/RahimAfrooz/Curing";
import ETP from "../components/RahimAfrooz/ETP";
import PlateDry from "../components/RahimAfrooz/PlateDry";
import PasteMixing from "../components/RahimAfrooz/PasteMixing";
import Substation from "../components/RahimAfrooz/Substation";
import moment from "moment";

const LivePage = () => {
  const [summary, setSummary] = useState([]);
  const [curingData, setCuringData] = useState([]);
  const [etpData, setEtpData] = useState([]);
  const [pdoData, setPdoData] = useState([]);
  const [pasteMisxingData, setPasteMixingData] = useState([]);
  const [energyData, setEnergyData] = useState([]);
  const { selectedFactoryId } = useUserContext();
  const { user, http } = AuthUser();
  //Floor id
  const [selectedFloorId, setSelectedFloorId] = useState(0);
  //Floor Name
  const [selectedCompatment, setSelectedCompartment] = useState("");
  const [dataUpdateInfo, setDataUpdateInfo] = useState(null);

  const navigate = useNavigate();

  //get total machine summary
  const fetchTotalMachineSummary = async () => {
    getLiveWeatherData(selectedFloorId)
      .then((data) => {
        setDataUpdateInfo({
          factory: data.factory,
          server_time: data.server_time,
        });
        const categorizedData = data.data.reduce(
          (accumulator, entry) => {
            const sensorType = entry.sensor.sensor;
            switch (sensorType) {
              case "curing":
                accumulator.curing.push(entry);
                break;
              case "etp":
                accumulator.etp.push(entry);
                break;
              case "pdo":
                accumulator.pdo.push(entry);
                break;
              case "pastemixing":
                accumulator.pastemixing.push(entry);
                break;
              case "energy":
                accumulator.energy.push(entry);
                break;
              default:
                break;
            }
            return accumulator;
          },
          { curing: [], etp: [], pdo: [], pastemixing: [], energy: [] }
        );

        // Update state with categorized data
        setCuringData(categorizedData.curing);
        setEtpData(categorizedData.etp);
        setPdoData(categorizedData.pdo);
        setPasteMixingData(categorizedData.pastemixing);
        setEnergyData(categorizedData.energy);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchTotalMachineSummary();
    const interval = setInterval(() => {
      fetchTotalMachineSummary();
    }, intervalTime);

    return () => clearInterval(interval);
  }, [selectedFactoryId, selectedFloorId]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    return () => {};
  }, []);

  const sensors = JSON.parse(sessionStorage.getItem("sensors"));

  return (
    <main
      className="main page-100">
      <section className="section-center">
        <div className="d-flex justify-content-between">
          <h3 className='pe-2'>Live</h3>
          <div className='text-end'>{`Live data update on ${moment(
            dataUpdateInfo?.server_time
          ).format("MMMM DD, YYYY - hh:mm a")} from ${
            dataUpdateInfo?.factory
          }`}</div>
        </div>
        <hr />

        <div className="my-4">
          <FloorTopbar
            selectedFloorId={selectedFloorId}
            setSelectedFloorId={setSelectedFloorId}
            selectedCompatment={selectedCompatment}
            setSelectedCompartment={setSelectedCompartment}
          />
        </div>

        <hr />

        <div
          className="row"
          style={
            {
              // maxWidth: "1100px",
              // margin: "auto",
              // overflow: "visible",
              // paddingLeft: "0.5rem",
              // paddingRight: "0.5rem",
            }
          }
        >
          {/* {sensors?.find((s) => s?.sensor === "RHT") && (
          <span className="m-2 fw-bold">Temperature & Humidity Sensor</span>
        )} */}
          {curingData?.length > 0 && <h3>Curing Section</h3>}
          {curingData?.map((cd, i) => {
            return <Curing key={i} {...cd} />;
          })}
        </div>

        <div className="mt-4 mb-4">
          <div className="row">
            {etpData?.length > 0 && <h3>Effluent Treatment Plant (ETP)</h3>}
            {etpData?.map((etp, i) => {
              return <ETP key={i} {...etp} />;
            })}
          </div>
        </div>

        <div className="mt-4 mb-4">
          <div className="row">
            {pdoData?.length > 0 && <h3>Plate Drying Section</h3>}
            {pdoData?.map((pdo, i) => {
              return <PlateDry key={i} {...pdo} />;
            })}
          </div>
        </div>

        <div className="mt-4 mb-4">
          <div className="row">
            {pasteMisxingData?.length > 0 && <h3>Paste Mixing</h3>}
            {pasteMisxingData?.map((pm, i) => {
              return <PasteMixing key={i} {...pm} />;
            })}
          </div>
        </div>

        <div className="mt-4 mb-4">
          <div className="row">
            {energyData?.length > 0 && <h3>Substation</h3>}
            {energyData?.map((st, i) => {
              return <Substation key={i} {...st} />;
            })}
          </div>
        </div>
      </section>
    </main>
  );
};

const Wrapper = styled.section`
  font-family: var(--ff-primary);
  .clr-btn {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: 0.1rem;
    border: none;
    margin: auto 0;
  }

  .machine-amount {
    color: var(--clr-primary-10);
    text-align: center;
    margin: auto 0;
    padding: 5px;
  }
`;
export default LivePage;
