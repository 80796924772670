import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";
import moment from "moment";
import { AiOutlineCalendar, AiOutlineClockCircle } from "react-icons/ai";
import Skeleton from "@material-ui/lab/Skeleton";


const CustomTooltip = ({ active, payload, label,dataKey }) => {

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip shadow text-start">
        <h5 className="text-center">{`${payload[0]?.payload[dataKey]}`}</h5>
        <p>
          <AiOutlineClockCircle size={18} />
          <span className="text-center">{` ${moment(
            payload[0].payload["acquisition-time"]
          ).format("hh:mm A")}`}</span>
        </p>
        {/* <p>
          <AiOutlineCalendar size={18} />
          <span className="text-center"> {date}</span>
        </p> */}
      </div>
    );
  }

  return null;
};

const GraphData = ({
  title,
  data_key,
  report,
  diffDays,
  loading,
  state,
  cTf,
  startDate,
  endDate,
}) => {

  function formatXAxis(tickItem) {
    return moment(tickItem).format("HH mm a");
  }
  function formatXAxis2(tickItem) {
    return moment(tickItem).format("MMM DD");
  }

  if (loading) {
    return <Skeleton variant="rect" width="100%" height={300} />;
  }

  return (
    <Wrapper className="mb-4">
        <h4>{title}</h4>
      <div className="chart">
        <ResponsiveContainer>
          <LineChart
            width={500}
            height={300}
            data={report}
            margin={{
              right: 20,
            }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false}
              strokeWidth={0.5}
            />
            <XAxis
              dataKey="acquisition-time"
              xAxisId={0}
              interval={360}
              tickFormatter={formatXAxis}
            />

            <XAxis
              dataKey="acquisition-time"
              tickFormatter={formatXAxis2}
              xAxisId={1}
              interval={1424}
              tickLine={false}
            />
            <YAxis
              dataKey={data_key}
              type="number"
              allowDataOverflow={true}
              domain={[
                (dataMin) => Math.abs(dataMin) - 5,
                (dataMax) => Math.abs(dataMax) + 5,
              ]}
            />
            <Tooltip content={<CustomTooltip dataKey={data_key} />} />
            <Line
              type="monotone"
              dataKey={data_key}
              stroke="#4040F2"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .chart {
    width: 100%;
    height: 300px;
    /* background-color: var(--clr-primary-1); */
    border-radius: 8px;
  }
  .custom-tooltip {
    background-color: var(--clr-grey-10);
    padding: 5px;
    text-align: center;
    color: var(--clr-primary-1);
  }
  .iconDetails {
    margin-top: 5px;
    width: 30px;
    height: 25px;
    padding-right: 5px;
  }
`;

export default GraphData;
