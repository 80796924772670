import React from "react";

const ETP = ({sensor_name,sensor_data,color}) => {
  const {inletFlow,outletFlow,inletPH,outletPH,Conductivity,stageNo,statusCode,runTime,programNo} =  sensor_data;

  return (
    <div className="col-md-6 mb-4">
      <div
        className="card bg-white ps-4 pt-3 pb-3 shadow"
        style={{ borderRadius: "5px" }}
      >
        <div className="d-flex justify-content-start ">
          <div
            style={{
              height: "15px",
              width: "15px",
              backgroundColor: statusCode == 'on' ? 'green' : 'red',
              borderRadius: "50%",
              marginTop: "1%",
            }}
          ></div>
         <p className="ms-2 mb-2 fw-bold text-info text-uppercase">{sensor_name}</p>
        </div>

        <div className="d-flex justify-content-start flex-wrap gap-5">
          <div className="d-flex flex-column"><span className="fw-bold">Inlet Flow</span>   <span className="fs-4  text-muted fw-bold">{parseFloat(inletFlow)?.toFixed(2)} m³/hour</span> </div>
          <div className="d-grid"><span className="fw-bold">Outlet Flow</span>  <span className="fs-4  text-muted fw-bold">{parseFloat(outletFlow)?.toFixed(2)} m³/hour</span> </div>
          <div className="d-grid"><span className="fw-bold">Conductivity</span>   <span className="fs-4  text-muted fw-bold">{parseFloat(Conductivity)?.toFixed(2)} μS/cm</span> </div>
          <div className="d-grid"><span className="fw-bold">Inlet Ph</span> <span className="fs-4  text-muted fw-bold">{parseFloat(inletPH)?.toFixed(2)}</span> </div>
          <div className="d-grid"><span className="fw-bold">Outlet Ph</span> <span className="fs-4  text-muted fw-bold">{parseFloat(outletPH)?.toFixed(2)}</span> </div>
        </div>
      </div>
    </div>
  );
};

export default ETP;
