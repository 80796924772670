import React from "react";

const PlateDry = ({ sensor_name, sensor_data, color }) => {
  const { presentTemperature, setTemperature, statusCode, runTime } =
    sensor_data;

  return (
    <div className="col-md-4 mb-4">
      <div
        className="card bg-white ps-4 pt-3 pb-3 shadow"
        style={{ borderRadius: "5px" }}
      >
        <div className="d-flex justify-content-start ">
          <div
            style={{
              height: "15px",
              width: "15px",
              backgroundColor: statusCode == "on" ? "green" : "red",
              borderRadius: "50%",
              marginTop: "1%",
            }}
          ></div>
          <p className="ms-2 mb-2 fw-bold text-info text-uppercase">
            {sensor_name}
          </p>
        </div>

        <div className="d-flex justify-content-start flex-wrap gap-4 mb-4">
          <div className="d-grid">
            <span className="fw-bold">Present Temperature</span>{" "}
            <span className="fs-4 text-muted fw-bold mb-2">
              {parseFloat(presentTemperature)?.toFixed(2)} &deg;C
            </span>{" "}
          </div>
          <div className="d-grid">
            <span className="fw-bold">Set Temperature</span>{" "}
            <span className="fs-4 text-muted fw-bold mb-2">
              {parseFloat(setTemperature)?.toFixed(2)} &deg;C
            </span>{" "}
          </div>
        </div>

        <div className="d-grid">
          <span className="fw-bold">Status</span>{" "}
          <span className="fs-4 text-muted fw-bold mb-4">{statusCode}</span>{" "}
        </div>
        <div className="d-grid">
          <span className="fw-bold">Running Time</span>{" "}
          <span className="fs-4 text-muted fw-bold mb-2">{runTime}</span>{" "}
        </div>
      </div>
    </div>
  );
};

export default PlateDry;
