import React from "react";
import GraphView from "./GraphView";
import TableView from "./TableView";

const SortView = ({
  table_ref,
  report,
  table_report,
  graph_view,
  loading,
  thresoldValues,
  state,
  handleCheck,
  cTf,
  reportType
}) => {
  if (graph_view) {
    return (
      <GraphView
        report={report}
        thresoldValues={thresoldValues}
        loading={loading}
        state={state}
        handleCheck={handleCheck}
        cTf={cTf}
        reportType={reportType}
      >
        Sort view
      </GraphView>
    )
  }

  else{
    return (
     <></>
    )
  }
};

export default SortView;
