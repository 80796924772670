import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import AuthUser from "../components/AuthUser";
import EnvMonitoring from "../components/EnvMonitoring";
import FloorTopbar from "../components/FloorTopbar";
import Motion from "../components/Motion";
import { useUserContext } from "../context/user_context";
import { intervalTime } from "../utils/constants";

const Overview = () => {
  const { user, http } = AuthUser();
  const [summary, setSummary] = useState([]);
  const [todaysSummary, setTodaysSummary] = useState([]);
  const [days, setDays] = useState(15);
  const { selectedFactoryId } = useUserContext();
  const [selectedFloorId, setSelectedFloorId] = useState("");
  const [selectedCompatment, setSelectedCompartment] = useState("");
  const location = useLocation();

  // console.log('user', user);
  const updateDays = (days) => {
    setDays(parseInt(days));
  };

  const navigate = useNavigate();
  useEffect(() => {
    // console.log('user', user);
    if (!user) {
      navigate("/login");
    }
    if(user){
      // console.log(window.location.href)
      if(location.pathname='/'){
        navigate('/alarm')
      }
   
    }
    return () => {};
  }, []);

  //get total machine summary
  const fetchTotalMachineSummary = async () => {
    try {
      const { data } =
        user &&
        selectedFactoryId &&
        (await http.get(
          `/api/v2/factory-machine-summary?factory_id=${selectedFactoryId}`
        ));
      setSummary(data);
    } catch (error) {}
  };
  const fetchToDaysMachineSummary = async () => {
    try {
      const { data } =
        user &&
        selectedFactoryId &&
        (await http.get(
          `/api/v2/daily-production-summary?factory_id=${selectedFactoryId}`
        ));
      // setTodaysSummary(data);
      setSeconds(data);
    } catch (error) {}
  };

  // useEffect(()=>{
  //   console.log("dafdf:", todaysSummary);
  // },[todaysSummary])
  const setSeconds = (data) => {
    try{
      if(data.hours_left){
        data.hr_left = convertDurationToSeconds(data.hours_left);

      }
      if(data.loss_time){
        data.ls_time = convertDurationToSeconds(data.loss_time);
        
      }
      if(data.total_active_machine_hrs){
        data.actv_mchn = convertDurationToSeconds(
          data.total_active_machine_hrs
        );
      }

      data.ttl_time = fromHrtoSec(data.total_production_hrs);
      setTodaysSummary( data );
    }catch(e){
      console.log(e);
    }
    
    // console.log("todaysSummary:", todaysSummary);
  };
  function fromHrtoSec(hourString) {
    const hours = parseFloat(hourString); // Extract numeric value from string
    const seconds = hours * 60 * 60; // Convert hours to seconds
    return seconds;
  }
  function convertDurationToSeconds(durationString) {
    const components = durationString
      .split(/[hms]/)
      .filter((c) => c.length > 0);
    let seconds = 0;
    if (components.length > 0) {
      seconds += parseInt(components[components.length - 1], 10);
    }
    if (components.length > 1) {
      seconds += parseInt(components[components.length - 2], 10) * 60;
    }
    if (components.length > 2) {
      seconds += parseInt(components[components.length - 3], 10) * 60 * 60;
    }
    return seconds;
  }

  useEffect(() => {
    fetchTotalMachineSummary();
    fetchToDaysMachineSummary();
  }, [selectedFactoryId]);

  return (
    <Motion>
      <Wrapper className="page-100">
      <p className=" fw-bolder text-dark pt-4 pb-4" style={{ maxWidth: "1100px", margin: "0 auto", overflow: "visible",fontFamily:"Arial", paddingLeft:"0.5rem", paddingRight:"0.5rem" }}>Alarm History</p>
      <FloorTopbar
        selectedFloorId={selectedFloorId}
        setSelectedFloorId={setSelectedFloorId}
        selectedCompatment={selectedCompatment}
        setSelectedCompartment={setSelectedCompartment}
      />
        <div className="section">
          <div className="section-center">
            {/* <Hero /> */}
            {/* <div>
              <p className="ms-4 fw-bolder text-dark">Machine Status</p>
              <div className="row mb-4">
                <div className="col-md-3">
                  <Card
                    title="total machine"
                    amount={summary.total}
                    data={[{ name: 1, value: summary.total }]}
                    colors={["#44C13C", "#daf3d8"]}
                  />
                </div>
                <div className="col-md-3">
                  <Card
                    title="Active"
                    color="#07C17E"
                    amount={summary.active}
                    data={[
                      { name: 2, value: summary.active },
                      { name: 1, value: summary.total },
                    ]}
                    colors={["#3C61C1", "#C1D6FF"]}
                  />
                </div>
                <div className="col-md-3">
                  <Card
                    title="idle"
                    color="#FEC400"
                    amount={summary.idle}
                    data={[
                      { name: 2, value: summary.idle },
                      { name: 1, value: summary.total },
                    ]}
                    colors={["#C13C3C", "#FBD9D9"]}
                  />
                </div>
                <div className="col-md-3">
                  <Card
                    title="off-line"
                    color="#E0125A"
                    amount={summary.offline}
                    data={[
                      { name: 2, value: summary.offline },
                      { name: 1, value: summary.total },
                    ]}
                    colors={["#FEC400", "#FBF8D9"]}
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="mt-5">
            <MachineStatus />

            </div>
            <div>
              <p className="ms-4 fw-bolder text-dark">Today’s Machine Status</p>
              <div className="row mb-4">
                <div className="col-lg-6 col-xl-3">
                  <Card
                    title="Expected Machine Hours"
                    amount={todaysSummary.total_production_hrs}
                    data={[
                      { name: 1, value: todaysSummary.ttl_time },
                    ]}
                    colors={["#44C13C", "#daf3d8"]}
                  />
                </div>
                <div className="col-lg-6 col-xl-3">
                  <Card
                    title="Active Machines Hours"
                    color="#07C17E"
                    amount={todaysSummary.total_active_machine_hrs}
                    data={[
                      { name: 2, value: todaysSummary.actv_mchn },
                      { name: 1, value: todaysSummary.ttl_time },
                    ]}
                    colors={["#3C61C1", "#C1D6FF"]}
                  />
                </div>
                <div className="col-lg-6 col-xl-3">
                  <Card
                    title="Loss Time Hours"
                    color="#FEC400"
                    amount={todaysSummary.loss_time}
                    data={[
                      { name: 2, value: todaysSummary.ls_time },
                      { name: 1, value: todaysSummary.ttl_time },
                    ]}
                    colors={["#C13C3C", "#FBD9D9"]}
                  />
                </div>
                <div className="col-lg-6 col-xl-3">
                  <Card
                    title="Leftover Hours"
                    color="#E0125A"
                    amount={todaysSummary.hours_left}
                    data={[
                      { name: 2, value: todaysSummary.hr_left },
                      { name: 1, value: todaysSummary.ttl_time },
                    ]}
                    colors={["#FEC400", "#FBF8D9"]}
                  />
                </div>
              </div>
            </div> */}
            {/* Inactive machine list */}

            <div className="row mt-4">
           
              <div className="col-md-12 mb-4">
                <div className="title">
                  {/* <InactiveMachine />
                   */}
                   <EnvMonitoring/>
                </div>
              </div>
              {/* <div className="col-md-6 mb-4">
                <div className="title">
                  <h4>Today</h4>
                  <TodayMachine />
                </div>
              </div> */}
            </div>
            {/* performance history */}
{/* 
            <h4>Performance History</h4>
            <PerformanceIndicator />
            <HighChartComponent days={days} />
            <SortPerformance days={days} updateDays={updateDays} /> */}
          </div>
        </div>
      </Wrapper>
    </Motion>
  );
};

const Wrapper = styled.article`
  
`;

export default Overview;
