import React from "react";
import { convertTime } from "../../utils/helpers";

const Curing = ({sensor_name,sensor_data,color}) => {
  const {presentTemperature,setTemperature,presentHumidity,setHumidity,stageNo,statusCode,runTime,programNo} =  sensor_data;

  return (
    <div className="col-xl-3 col-lg-3 col-md-6 mb-4">
      <div
        className="card bg-white ps-4 pt-3 pb-3 shadow"
        style={{ borderRadius: "5px" }}
      >
        <div className="d-flex justify-content-start">
          <div
            style={{
              height: "15px",
              width: "15px",
              backgroundColor: statusCode == 'on' ? 'green' : 'red',
              borderRadius: "50%",
              marginTop: "1%",
            }}
          ></div>
         <p className="ms-2 fw-bold text-info text-uppercase">{sensor_name}</p>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="d-grid">
            <span className="fw-bold fs-6 mt-2">Tempearture</span>
            <span className="fs-4 mt-2 text-muted fw-bold">{parseFloat(presentTemperature)?.toFixed(2)} &deg;C</span>
          </div>
          <div className="d-grid text-center me-2">
            <span className="fw-bold fs-6">Set Temp.</span>
            <span className="fs-4 text-muted fw-bold">{parseFloat(setTemperature)?.toFixed(2)} &deg;C</span>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="d-grid">
            <span className="fw-bold fs-6 mt-2">Relative Humidity</span>
            <span className="fs-4 mt-2 text-muted fw-bold">{parseFloat(presentHumidity)?.toFixed(2)} %</span>
          </div>
          <div className="d-grid text-center me-2">
            <span className="fw-bold fs-6">Set RH</span>
            <span className="fs-4 text-muted fw-bold">{parseFloat(setHumidity)?.toFixed(2)} %</span>
          </div>
        </div>

        <span className="fw-bold fs-6 mt-2">Status</span>
        <span className="fs-4 text-muted fw-bold">{statusCode}</span>
       
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-grid">
            <span className="fw-bold fs-6 mt-2">Program No</span>
            <span className="fs-4 mt-2 text-muted fw-bold">{programNo || 1}</span>
          </div>
          <div className="d-grid text-center me-2">
            <span className="fw-bold fs-6">Stage </span>
            <span className="fs-4 text-muted fw-bold">{stageNo}</span>
          </div>
        </div>

        <span className="fw-bold fs-6 mt-2">Running Time</span>
        <span className="fs-4 mt-2 text-muted fw-bold">{runTime}</span>

      </div>
    </div>
  );
};

export default Curing;
