import axios from "axios";
import { URL } from "./constants";

export const getLiveWeatherData = async (pid=0) => {
  let token = JSON.parse(sessionStorage.getItem("token"));
  try {
    let {data} = await axios.get(
     `${URL}/pms/v1/live?process_id=${pid}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (e) {
    return new Error();
  }
};
export const getAlarmData = async (factory_id) => {
  let token = JSON.parse(sessionStorage.getItem("token"));
  try {
    let weather = await axios.get(
      `${URL}/api/v2/weather/daily-alarms?factory_id=${factory_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return weather.data;
  } catch (e) {
    return new Error();
  }
};
export const getReportData = async (start, end, factory_id, floor_id) => {
  let token = JSON.parse(sessionStorage.getItem("token"));
  try {
    let weather = await axios.get(
      `${URL}/api/v2/weather/report-tabular-floor?startDate=${start}&endDate=${end}&factory_id=${factory_id}&floor_id=${floor_id}&limit=5000&offset=0`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return weather.data;
  } catch (e) {
    return new Error();
  }
};
